import { React, useEffect} from 'react';
import "../Styling/AboutUs.css";
import omar from "../photos/about_us_photos/Omar.jpg"
import khoi from "../photos/about_us_photos/Khoi.jpg"
import jason from "../photos/about_us_photos/Jason.JPEG"
import ben from "../photos/about_us_photos/Ben.jpg"
import sydney from "../photos/about_us_photos/Sydney.JPEG"
import dron from "../photos/about_us_photos/Dron.jpg"
import michelle from "../photos/about_us_photos/Michelle.jpeg"
import ethan from "../photos/about_us_photos/Ethan.jpg"
import john from "../photos/about_us_photos/john.jpg"
import LinkedInIcon from '../photos/Linkedin-logo.png';

const AboutUs = () => {
    // included all key members of OC-Intern and their respective linkedin profile
    const members = {
        omar: "https://www.linkedin.com/in/omar-cs",
        khoi: "https://www.linkedin.com/in/khoibui105/",
        jason: "https://www.linkedin.com/in/chia-sheng-wang",
        ben: "https://www.linkedin.com/in/ben-khong/",
        sydney: "https://www.linkedin.com/in/sydneylevo/",
        dron: "https://www.linkedin.com/in/dron-nikam-a790b11a9/",
        michelle: "https://www.linkedin.com/in/michelle-nguyen-0a808732a/",
        ethan: "https://www.linkedin.com/in/ethan-v-935519328/",
        john: "https://www.linkedin.com/in/john-murindwa-aa9153248/"
    };

    // handle clicking the linkedin icon
    // direct a new page to the linkedin url
    const toLinkedin = (member) => {
        window.open(members[member], "_blank");
    }

    // refresh and relink to this page also goes to top of screen
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="about-container">
            <section className="business-section">
                <h1 className="business-heading">Our Business - OCInterns</h1>
                <p className="business-description">
                    
                At OC Interns, we bridge the gap between aspiring computer science students 
                and their first industry opportunity while also supporting businesses in 
                establishing a strong online presence. We understand the challenges students 
                face in securing internships amidst fierce competition and a lack of practical 
                experience. Simultaneously, we recognize the struggle businesses encounter in 
                navigating the complexities of website development and digital branding. Our 
                mission is to empower students with the skills and connections they need to 
                succeed and to deliver tailored, impactful websites that drive business growth.
                </p>
            </section>
            <div className="divider"></div>

            <section className="creators">
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={omar} alt="Creator 1" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Omar Abou-Harb</h2>
                                <p className="creator-role">Founder & CEO</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("omar")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={khoi} alt="Creator 2" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Khoi Bui</h2>
                                <p className="creator-role">Head of UI/UX</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("khoi")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={jason} alt="Creator 2" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Jason Wang</h2>
                                <p className="creator-role">Head of Full-Stack Development</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("jason")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={ben} alt="Creator 3" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Ben Khong</h2>
                                <p className="creator-role">Front-End Developer</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("ben")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={sydney} alt="Creator 3" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Sydney Vo</h2>
                                <p className="creator-role">Graphic Designer</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("sydney")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={dron} alt="Creator 3" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Dron Nikam</h2>
                                <p className="creator-role">Front-end Designer</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("dron")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={michelle} alt="Creator 5" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Michelle Nguyen</h2>
                                <p className="creator-role">Creative Director</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("michelle")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={ethan} alt="Creator 6" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">Ethan Vu</h2>
                                <p className="creator-role">Sales Director</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("ethan")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creator">
                    <div className='profileAndDetails'>
                        <div id="photo-name-title">
                            <div className="image-container">
                                <img src={john} alt="Creator 7" className="creator-image"/>
                            </div>
                            <div className="creator-details">
                                <h2 className="creator-name">John Kayihura</h2>
                                <p className="creator-role">Front-end Designer</p>
                                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" onClick={() => toLinkedin("john")}/>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default AboutUs;
